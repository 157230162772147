// src/pages/BlogPostDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import styled from 'styled-components';
import Footer from '../Footer';

// Styled Components
const DetailContainer = styled.div`
  max-width: 1300px;
  margin: auto;
  padding: 20px;
  
`;

const BackButton = styled.button`
  background-color: #2575fc;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 1rem;
  margin-top:50px;

  &:hover {
    background-color: #1a5bb8;
  }
`;

const PostImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const PostTitle = styled.h1`
  margin-top: 20px;
  font-size: 2rem;
  color: #FFFFFF;
`;

const PostContent = styled.p`
  margin-top: 10px;
  line-height: 1.8;
  font-size: 1.1rem;
  color: #FFFFFF;
`;

const Timestamp = styled.p`
  font-size: 0.875rem;
  color: #666666;
  margin-top: 10px;
`;

function BlogPostDetail() {
  const { id } = useParams(); // Get the post ID from the URL
  const navigate = useNavigate(); // To navigate back
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Fetch the single blog post from Firestore
    const fetchPost = async () => {
      try {
        const docRef = doc(db, 'posts', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPost({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log('No such document!');
          setError(true);
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  if (loading) {
    return (
      <>
        
        <DetailContainer>
          <p>Loading...</p>
        </DetailContainer>
      </>
    );
  }

  if (error || !post) {
    return (
      <>
      
        <DetailContainer>
          <p>Post not found.</p>
          <BackButton onClick={() => navigate(-1)}>Go Back</BackButton>
        </DetailContainer>
      </>
    );
  }

  return (
    <>
      
      <DetailContainer>
        <BackButton onClick={() => navigate(-1)}>← Back to Blog</BackButton>
        {post.imageUrl && <PostImage src={post.imageUrl} alt={post.title} />}
        <PostTitle>{post.title}</PostTitle>
        <PostContent>{post.content}</PostContent>
        <Timestamp>Posted on: {formatTimestamp(post.createdAt.toDate())}</Timestamp>
      </DetailContainer>
      <Footer />
    </>
  );
}

export default BlogPostDetail;
