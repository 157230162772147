// src/components/BlogPost.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styled Components
const PostContainer = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const PostImage = styled.img`
  width: 100%;
  height: 50vh;
  border-radius: 8px;
`;

const PostTitle = styled.h2`
  margin-top: 20px;
  font-size: 1.8rem;
  color: #FFFFFF;
`;

const PostContent = styled.p`
  margin-top: 10px;
  line-height: 1.6;
  font-size: 1rem;
  color: #FFFFFF;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #2575fc;
  cursor: pointer;
  padding: 0;
  font-size: 0.875rem;
  margin-top: 5px;
  display: block;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

function BlogPost({ post }) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle the expanded state
  const toggleContent = () => {
    setIsExpanded((prev) => !prev);
  };

  // Function to count words in the content
  const getWordCount = (text) => {
    return text.trim().split(/\s+/).length;
  };

  // Function to truncate content to a specific word limit
  const getTruncatedContent = (text, wordLimit) => {
    const words = text.trim().split(/\s+/);
    if (words.length <= wordLimit) return text;
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  const wordLimit = 50;
  const wordCount = getWordCount(post.content);
  const shouldTruncate = wordCount > wordLimit;
  const displayedContent =
    shouldTruncate && !isExpanded ? getTruncatedContent(post.content, wordLimit) : post.content;

  return (
    <StyledLink to={`/blog/${post.id}`}>
      <PostContainer>
        {post.imageUrl && <PostImage src={post.imageUrl} alt={post.title} />}
        <PostTitle>{post.title}</PostTitle>
        <PostContent>{displayedContent}</PostContent>
        {shouldTruncate && (
          <ToggleButton onClick={(e) => { e.preventDefault(); toggleContent(); }}>
            {isExpanded ? 'Show Less' : 'Show More'}
          </ToggleButton>
        )}
      </PostContainer>
    </StyledLink>
  );
}

export default BlogPost;
