import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { motion } from 'framer-motion'; // Import motion from framer-motion
import logo from './epoch.png'; // Correct path assuming the image is in the public folder

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [bgColor, setBgColor] = useState('bg-transparent'); // Initial background color
  // State to track if navbar is sticky

  // Function to close the mobile menu when a link is clicked
  const closeMenu = () => {
    setIsOpen(false);
  };

  // Effect to handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { // Change this value based on when you want to change the background
        setBgColor('bg-black'); // Change to gray when scrolled down
       // Stop being sticky after scrolling past 100px
      } else {
        setBgColor('bg'); // Keep it transparent when at the top
         // Make it sticky again when at the top
      }
    };

    window.addEventListener('scroll', handleScroll); // Attach event listener

   
  }, []);

  return (
    <nav className={`${bgColor} fixed text-gray-200 p-6 w-full z-10 transition duration-300`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/">
              <img src={logo} alt="Epochouse Logo" className="h-20" />
            </Link>
          </div>

          {/* Navigation Links (Centered, for larger screens) */}
          <div className="hidden md:flex space-x-8 mx-auto">
            <Link to="/" className="transition-colors duration-500 hover:text-red-400 underline-none">Home</Link>
            <Link to="/about" className="transition-colors duration-500 hover:text-red-400">About Us</Link>
            <Link to="/services" className="transition-colors duration-500 hover:text-red-400">Our Services</Link>
            <Link to="/blog" className="transition-colors duration-500 hover:text-red-400">Blog</Link>
            <Link to="/consultation" className="transition-colors duration-500 hover:text-red-400">Consultation</Link>
            <Link to="/contact" className="transition-colors duration-500 hover:text-red-400">Contact Us</Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-200 focus:outline-none"
            >
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -50 }} // Start off screen
          animate={{ opacity: 1, y: 0 }} // Fade in and slide down
          exit={{ opacity: 0, y: -50 }} // Fade out and slide up
          transition={{ duration: 0.5, ease: [0.25, 0.1, 0.25, 1] }} // Smoother ease-in-out
          className="md:hidden bg-black opacity-25 fixed inset-0 z-20 flex flex-col items-center justify-center"
        >
          {/* Close Button */}
          <button onClick={closeMenu} className="absolute top-6 right-6 text-white text-3xl">
            &times; {/* Close icon (X) */}
          </button>

          <div className="flex flex-col items-center space-y-6"> {/* Center links vertically with space in between */}
            <motion.div
              initial={{ scale: 0 }} // Initial scale for bouncy effect
              animate={{ scale: 1 }} // Animate to full size
              exit={{ scale: 0 }} // Scale down on exit
              transition={{ duration: 0.5, type: "spring", stiffness: 200 }} // Bouncy effect
            >
              <Link
                to="/"
                className="block text-white text-2xl font-semibold hover:bg-red-500 hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
                onClick={closeMenu}
              >
                Home
              </Link>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.6, type: "spring", stiffness: 250 }} // Different duration and stiffness
            >
              <Link
                to="/about"
                className="block text-white text-2xl font-semibold hover:bg-red-500 hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
                onClick={closeMenu}
              >
                About Us
              </Link>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.7, type: "spring", stiffness: 200 }} // Another variation
            >
              <Link
                to="/services"
                className="block text-white text-2xl font-semibold hover:bg-red-500 hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
                onClick={closeMenu}
              >
                Our Services
              </Link>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.8, type: "spring", stiffness: 150 }} // Another variation
            >
              <Link
                to="/blog"
                className="block text-white text-2xl font-semibold hover:bg-red-500 hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
                onClick={closeMenu}
              >
                Blog
              </Link>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.9, type: "spring", stiffness: 100 }} // Another variation
            >
              <Link
                to="/consultation"
                className="block text-white text-2xl font-semibold hover:bg-red-500 hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
                onClick={closeMenu}
              >
                Consultation
              </Link>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 1.0, type: "spring", stiffness: 50 }} // Another variation
            >
              <Link
                to="/contact"
                className="block text-white text-2xl font-semibold hover:bg-red-500 hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
                onClick={closeMenu}
              >
                Contact Us
              </Link>
            </motion.div>
          </div>
        </motion.div>
      )}
    </nav>
  );
};

export default Navbar;
