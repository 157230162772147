import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Home from './pages/Home';
import ContactUs from './pages/ContactUs'; 
import AboutUs from './pages/AboutUs';
import ConsultUs from './pages/ConsultUs';
import OurServices from './pages/OurServices';
import Blog from './pages/Blog';
import BlogPostDetail from './pages/BlogPostDetail';
import Admin from './pages/Admin';
import { AuthProvider } from './contexts/AuthContext';  // Import the AuthProvider
import SmoothScrollWrapper from './components/SmoothScrollWrapper'; // Import SmoothScrollWrapper

const App = () => {
  return (
    <AuthProvider>
      <SmoothScrollWrapper> {/* Wrap entire app with SmoothScrollWrapper */}
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/consultation" element={<ConsultUs />} />
            <Route path="/services" element={<OurServices />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPostDetail />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </Router>
      </SmoothScrollWrapper>
    </AuthProvider>
  );
};

export default App;
