import React, { useState, useEffect } from 'react';
import Fiverr from './Fiverr.png';
import airgigs from './airgigs.png';
import SoundBetter from './SoundBetter.png';
import WhatsApp from './whatsapp';
import Navbar from './Navbar';
// import { Link } from 'react-router-dom';

const images = [
  'https://res.cloudinary.com/dts134dyc/image/upload/v1731867975/Rectangle_280-min_ojg9et.jpg',
  // 'https://res.cloudinary.com/dts134dyc/image/upload/v1729168570/Photoroom-20241017_125550_3_x3tlic.webp',
  // 'https://res.cloudinary.com/dts134dyc/image/upload/v1729167646/hero2_qoqyti.webp',
];

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [fade, setFade] = useState(true);

  const changeImage = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentImage((prev) => {
        const nextIndex = (images.indexOf(prev) + 1) % images.length;
        return images[nextIndex];
      });
      setFade(true);
    }, 500);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        changeImage();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const heroSection = document.querySelector('.Hero-Section');
    const observer = new IntersectionObserver(
      (entries) => {
        if (!entries[0].isIntersecting) {
          changeImage();
        }
      },
      { threshold: 0.1 }
    );

    if (heroSection) {
      observer.observe(heroSection);
    }

    return () => {
      if (heroSection) {
        observer.unobserve(heroSection);
      }
    };
  }, []);

  return (
    <>
      <Navbar />
      <WhatsApp />
      <section
        className="relative Hero-Section"
        style={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          color: 'white',
          backgroundImage: `url(${currentImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          transition: 'opacity 0.5s ease-in-out',
          opacity: fade ? 1 : 0,
          // backgroundAttachment:'fixed',
        }}
      >
        {/* Text Content */}
        <div className="hero-container px-4 lg:px-0 text-center lg:text-left">
          <div className="Header-Div" style={{ zIndex: 2 }}>
            <h1 className="epoch-text text-3xl md:text-5xl lg:text-7xl font-bold leading-tight">
              <span className="epoch-line">THE EPOCHOUSE</span>
            </h1>

            <p className="mt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit excepturi itaque officia! Totam, eligendi vitae neque rem magni officiis nobis nulla laboriosam similique ipsa, ut, nihil corrupti sit assumenda est?</p>


{/* <Link to="/services" className="inline-block mt-6">
            <button className="Hero-btn transition-transform transform hover:scale-105">
              Explore
            </button>
            </Link> */}

<div className="  space-x-4 logos-div" style={{ zIndex: 2 }}>
          <a href="https://www.fiverr.com/itunujoe" target="_blank" rel="noopener noreferrer">
  <button className="bg-white text-black p-2 rounded-lg shadow-lg border border-black hover:bg-gray-200 transition-transform transform hover:scale-110">
    <img src={Fiverr} alt="fiverr-img" className="w- h-8" />
  </button>
</a>

<a href="https://www.airgigs.com/user/itunujoe" target="_blank" rel="noopener noreferrer">
  <button className="bg-white text-black p-2 rounded-lg shadow-lg border border-black hover:bg-gray-200 transition-transform transform hover:scale-110">
    <img src={airgigs} alt="airgigs" className="w- h-8" />
  </button>
</a>

<a href="https://soundbetter.com/profiles/127026-itunu-joe" target="_blank" rel="noopener noreferrer">
  <button className="bg-white text-black p-2 rounded-lg shadow-lg border border-black hover:bg-gray-200 transition-transform transform hover:scale-110">
    <img src={SoundBetter} alt="SoundBetter" className="w- h-8" />
  </button>
</a>

          </div>
            
          </div>

          {/* Scroll Down Arrow */}
          <div
            className="absolute bottom-10 left-1/2 transform -translate-x-1/2 Scroll-btn transition-transform hover:scale-150 Hero-Arrow"
            style={{ zIndex: 2 }}
          >
            <svg
              onClick={() => document.getElementById('service').scrollIntoView({ behavior: 'smooth' })}
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-white cursor-pointer animate-bounce"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
          </div>

          {/* 3 Buttons at the Bottom-Right Corner */}
        </div>
          
      </section>
    </>
  );
};

export default Hero;
