
import { RxCrop } from "react-icons/rx";


export const serviceData =[
  {icon:RxCrop, title: `Music Production`, image: `https://res.cloudinary.com/dts134dyc/image/upload/v1729171209/WhatsApp_Image_2024-10-17_at_14.13.00_1fb433af_ni39vj.jpg` },
  { icon:RxCrop,title: `Session Musicians`, image: `https://res.cloudinary.com/dts134dyc/image/upload/v1729171207/WhatsApp_Image_2024-10-17_at_14.12.59_a4bc8152_ez5kbb.jpg` },
  {icon:RxCrop, title: `Background Singers`, image: `https://res.cloudinary.com/dts134dyc/image/upload/v1729168571/Photoroom-20241017_130149_2_fkdvnp.webp` },
  { icon:RxCrop,title: `Mixing and Mastering`, image: `https://res.cloudinary.com/dts134dyc/image/upload/v1729171206/WhatsApp_Image_2024-10-17_at_14.12.55_a77a9964_ivq287.jpg` },
  { icon:RxCrop,title: `Show Production`, image: `https://res.cloudinary.com/dts134dyc/image/upload/v1729168570/Photoroom-20241017_130149_1_vlxq8a.webp` },
  { icon:RxCrop,title: `Live Recording`, image: `https://res.cloudinary.com/dts134dyc/image/upload/v1729168571/Photoroom-20241017_130149_4_xoz0y5.webp` },
];