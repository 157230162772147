import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './index.css'; // We'll write the styles separately for better structure

const WhatsApp = () => {
  return (
    <a
      href="https://wa.me/message/L4UXNZTPWMLSE1" // Replace with your WhatsApp number (without '+' sign)
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
    >
      <FaWhatsapp size={35} />
    </a>
  );
};

export default WhatsApp;


