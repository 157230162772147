// src/pages/Admin.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { storage, db, auth } from '../services/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, deleteDoc, doc, Timestamp, onSnapshot } from 'firebase/firestore';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { Formik, Form, Field, ErrorMessage as FormikErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../contexts/AuthContext';


// Styled Components
const AdminContainer = styled.div`
  max-width: 1000px;
  margin: 60px auto;
  padding: 40px 30px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

  /* Responsive Design */
  @media (max-width: 768px) {
    margin: 20px;
    padding: 30px 20px;
  }
`;


// Container for each input field
const InputField = styled.div`
  margin-bottom: 25px;
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333333;
    font-size: 1rem;
  }

  input,
  textarea {
    width: 90%;
    padding: 12px 15px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    margin-bottom: 10px;
    
    &:focus {
      border-color: #007bff;
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
    }
  }

  /* Specific styles for file input */
  input[type="file"] {
    padding: 5px;
  }
`;

// Styled component for displaying error messages
const ErrorText = styled.div`
  margin-top: 5px;
  color: #e74c3c;
  font-size: 0.875rem;
`;

// Styled Button with Gradient and Hover Effects
const Button = styled.button`
  width: 20%;
  padding: 14px 20px;
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;

  &:hover:enabled {
    background: linear-gradient(45deg, #2575fc, #6a11cb);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
    box-shadow: none;
  }

   @media (max-width: 768px) {
    margin: 5px;
    font-size: 12px;
    width:30%;

  }
`;

// Use transient prop by prefixing with $
const StatusMessage = styled.p`
  margin-top: 20px;
  padding: 12px 15px;
  border-radius: 8px;
  background-color: ${({ $success }) => ($success ? '#d4edda' : '#f8d7da')};
  color: ${({ $success }) => ($success ? '#155724' : '#721c24')};
  border: 1px solid ${({ $success }) => ($success ? '#c3e6cb' : '#f5c6cb')};
  font-size: 0.95rem;
  transition: opacity 0.3s ease;
  width:90%;


  /* Fade-out effect */
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

// Format the timestamp for display
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

// Main Admin Component
function Admin() {
  const { currentUser } = useAuth();
  const [uploadStatus, setUploadStatus] = useState({ message: '', success: false });
  const [uploadButtonText, setUploadButtonText] = useState('Upload Post');
  const [timer, setTimer] = useState(null);
  const [posts, setPosts] = useState([]); // State for posts

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  // Fetch existing posts from Firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'posts'), (snapshot) => {
      const postList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPosts(postList);
    });
    return () => unsubscribe(); // Clean up listener on unmount
  }, []);

  // Validation schemas for Login and Post Creation
  const loginValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
  });

  const postValidationSchema = Yup.object({
    title: Yup.string().required('Required'),
    content: Yup.string().required('Required'),
  });

  // Initial values for Login and Post Creation
  const loginInitialValues = {
    email: '',
    password: '',
  };

  const postInitialValues = {
    title: '',
    content: '',
    image: null,
  };

  // Handle Login Submission
  const handleLogin = async (values, { setSubmitting }) => {
    console.log('Login form submitted with values:', values); // Debugging
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      setUploadStatus({ message: 'Logged in successfully.', success: true });
      console.log('Logged in successfully.');
    } catch (error) {
      console.error('Authentication error:', error);
      setUploadStatus({ message: 'Authentication failed. Please check your credentials.', success: false });
    }
    setSubmitting(false);
  };

  // Handle Logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUploadStatus({ message: 'Logged out successfully.', success: true });
      console.log('Logged out successfully.');
    } catch (error) {
      console.error('Logout error:', error);
      setUploadStatus({ message: 'Logout failed.', success: false });
    }
  };

  // Handle Post Submission
  const handlePostSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log('Post form submitted with values:', values); // Debugging
    // Set button text to 'Uploading...' at the start
    setUploadButtonText('Uploading...');
    try {
      let imageUrl = '';
      if (values.image) {
        const imageRef = ref(storage, `images/${values.image.name}_${Date.now()}`);
        const snapshot = await uploadBytes(imageRef, values.image);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      await addDoc(collection(db, 'posts'), {
        title: values.title,
        content: values.content,
        imageUrl,
        createdAt: Timestamp.fromDate(new Date()),
      });

      setUploadStatus({ message: 'Post uploaded successfully.', success: true });
      resetForm();
      console.log('Post uploaded successfully.');

      // Update button text to 'Uploaded successfully'
      setUploadButtonText('Uploaded successfully');

      // Reset button text after 3 seconds
      const newTimer = setTimeout(() => {
        setUploadButtonText('Upload Post');
      }, 3000);
      setTimer(newTimer);
    } catch (error) {
      console.error('Error uploading post:', error);
      setUploadStatus({ message: 'Failed to upload post.', success: false });

      // Update button text to 'Upload Failed'
      setUploadButtonText('Upload Failed');

      // Reset button text after 3 seconds
      const newTimer = setTimeout(() => {
        setUploadButtonText('Upload Post');
      }, 3000);
      setTimer(newTimer);
    }
    setSubmitting(false);
  };

  // Handle Post Deletion
  const handleDeletePost = async (postId) => {
    try {
      await deleteDoc(doc(db, 'posts', postId));
      setUploadStatus({ message: 'Post deleted successfully.', success: true });
      console.log('Post deleted successfully.');
    } catch (error) {
      console.error('Error deleting post:', error);
      setUploadStatus({ message: 'Failed to delete post.', success: false });
    }
  };

  return (
    <>
    <AdminContainer>
      <h1>Admin Panel</h1>
      {!currentUser ? (
        // Login Form
        <Formik
          initialValues={loginInitialValues}
          validationSchema={loginValidationSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputField>
                <label htmlFor="email">Email:</label>
                <Field type="email" name="email" placeholder="Enter your email" />
                <FormikErrorMessage name="email" component={ErrorText} />
              </InputField>
              <InputField>
                <label htmlFor="password">Password:</label>
                <Field type="password" name="password" placeholder="Enter your password" />
                <FormikErrorMessage name="password" component={ErrorText} />
              </InputField>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Logging in...' : 'Login'}
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          {/* Logout Button */}
          <Button onClick={handleLogout} style={{ marginBottom: '20px' }}>
            Logout
          </Button>

          {/* Post Creation Form */}
          <Formik
            initialValues={postInitialValues}
            validationSchema={postValidationSchema}
            onSubmit={handlePostSubmit}
          >
            {({ setFieldValue, isSubmitting }) => (
              <Form>
                <InputField>
                  <label htmlFor="title">Title</label>
                  <Field type="text" name="title" placeholder="Enter post title" />
                  <FormikErrorMessage name="title" component={ErrorText} />
                </InputField>
                <InputField>
                  <label htmlFor="content">Content</label>
                  <Field as="textarea" name="content" rows="5" placeholder="Enter post content" />
                  <FormikErrorMessage name="content" component={ErrorText} />
                </InputField>
                <InputField>
                  <label htmlFor="image">Image</label>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(event) => {
                      setFieldValue('image', event.currentTarget.files[0]);
                    }}
                  />
                </InputField>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Uploading...' : uploadButtonText}
                </Button>
              </Form>
            )}
          </Formik>

          {/* Display Existing Posts */}
          <h2 style={{ marginTop: '40px' }}>Existing Posts</h2>
          {posts.length > 0 ? (
            posts.map((post) => (
              <div key={post.id} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #dddddd', borderRadius: '8px' }}>
                <h3>{post.title}</h3>
                <p>{post.content}</p>
                {post.imageUrl && <img src={post.imageUrl} alt={post.title} style={{ maxWidth: '100%', borderRadius: '8px' }} />}
                <p style={{ fontSize: '0.875rem', color: '#666666' }}>
                  Posted on: {formatTimestamp(post.createdAt.toDate())}
                </p>
                <Button onClick={() => handleDeletePost(post.id)} style={{ marginTop: '10px' }}>
                  Delete Post
                </Button>
              </div>
            ))
          ) : (
            <p>No posts available.</p>
          )}
        </>
      )}
      {uploadStatus.message && (
        <StatusMessage $success={uploadStatus.success} visible={true}>
          {uploadStatus.message}
        </StatusMessage>
      )}
    </AdminContainer>
    </>
  );
}

export default Admin;
