import React from "react";
import { Helmet } from 'react-helmet';
import Navbar from "../Navbar";
import Hero from '../Hero';
import CreativeDirector from '../CreativeDirector';
import Videos from '../Videos';
import Footer from '../Footer';
import ActiveSlider from "../components/ActiveSlider";



const Home = () => {


return(
    <>
<Helmet>
  <title>Home | Epoch House</title>
  <meta name="description" content="Welcome to Epoch House, a premier destination for music production, live recording, and show production services." />
</Helmet>
<Navbar />
<Hero />
<ActiveSlider />
<CreativeDirector />
<Videos />
<Footer />

    </>
)
};

export default Home;

