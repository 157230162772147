import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { FreeMode, Pagination, Navigation } from "swiper/modules"; 
import { RxArrowTopRight } from "react-icons/rx";
import { serviceData } from "../Services";
import { useNavigate } from "react-router-dom"; 

const ActiveSlider = () => {
  const navigate = useNavigate();

  return (
    <div id="service" className="flex items-center justify-center flex-col px-3 py-5 services">
      <h1 className="font-semibold text-white text-5xl ">Our Services</h1>
      <p className="text-gray-200 text-xl pb-8 pt-4">
        We Provide a Variety of Services
      </p>

      {/* Arrows for slider navigation */}
      <div className="relative w-full max-w-[100%] lg:max-w-[90%] mx-auto">
        {/* Left Arrow */}
        <div className="absolute z-20 left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => document.querySelector('.swiper-button-prev').click()}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </div>

        {/* Right Arrow */}
        <div className="absolute z-20 right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => document.querySelector('.swiper-button-next').click()}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>

        <Swiper
          spaceBetween={10}
          navigation={true} // Enables navigation arrows
          breakpoints={{
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
          }}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination, Navigation]} 
          className="w-full max-w-[100%] lg:max-w-[90%] mx-auto"
        >
          {serviceData.map((item) => (
            <SwiperSlide key={item.title}>
              <div
                onClick={() => navigate("/services")} // Navigates to '/services' path on click
                className="relative flex flex-col group shadow-lg rounded-lg overflow-hidden h-[400px] lg:h-[450px] w-full cursor-pointer transition-transform duration-300 transform group-hover:scale-120" // Subtle hover animation
              >
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${item.image})` }}
                />
                <div className="absolute inset-0 bg-black opacity-40 group-hover:opacity-80 transition-opacity duration-300" />
                <div className="relative flex flex-col p-6 gap-3 z-10">
                  <item.icon className="text-blue-600 group-hover:text-blue-400 w-8 h-8" />
                  <h1 className="text-3xl lg:text-3xl text-white">{item.title}</h1>
                </div>
                <RxArrowTopRight className="absolute bottom-5 left-5 w-9 h-9 text-white group-hover:text-blue-500 group-hover:rotate-45 transition-transform duration-300" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ActiveSlider;
