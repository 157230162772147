import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from './epoch.png';

const Footer = () => {
    return (
        <footer className="Footer-div  text-gray-300 py-16">
            <div className="footer-container container mx-auto grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2  gap-8">
                
                {/* Logo */}
                <div className="footer-img">
                    <img 
                        src={logo}
                        alt="Logo" 
                        className="mb-4" 
                    />
                </div>
                
                {/* Navigation */}
                <div>
                    <h3 className="text-lg font-semibold mb-4">Menu</h3>
                    <ul>
                        <li className="transition-colors duration-500 mb-2 hover:text-red-400">
                            <Link to="/blog">Blog</Link>
                        </li>
                        <li className="transition-colors duration-500 mb-2 hover:text-red-400">
                            <Link to="/services">Our Services</Link>
                        </li>
                        <li className="transition-colors duration-500 mb-2 hover:text-red-400">
                            <Link to="/contact">Contact Us</Link>
                        </li>
                        <li className="transition-colors duration-500 mb-2 hover:text-red-400">
                            <Link to="/consultation">Consultation</Link>
                        </li>
                    </ul>
                </div>

                {/* Address */}
                <div>
                    <h3 className="text-lg font-semibold mb-4">Location</h3>
                    <p>Address:</p>
                    <a href='https://maps.app.goo.gl/gC4LSgkYMtq1shVd7'>
                    <p>1234 Some Street, Some City</p>
                    </a>
                    <p>Phone: (123) 456-7890</p>
                </div>

                {/* Opening Hours & Follow Us */}
                <div>
                    <h3 className="text-lg font-semibold mb-4">Opening Hours</h3>
                    <p>Monday - Saturday: 9am - 6pm</p>
                    <p>Sunday: Closed</p>
                    <div className="mt-4">
                        <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
                        <div className="flex space-x-4">
                            <a href="https://www.facebook.com" target="_blank" rel="noreferrer" className="hover:text-blue-600" aria-label="Facebook">
                                <FaFacebook className="w-6 h-6" />
                            </a>
                            <a href="https://www.instagram.com" target="_blank" rel="noreferrer" className="hover:text-gray-500" aria-label="Instagram">
                                <FaInstagram className="w-6 h-6" />
                            </a>
                            <a href="https://www.twitter.com" target="_blank" rel="noreferrer" className="hover:text-indigo-600" aria-label="Twitter">
                                <FaTwitter className="w-6 h-6" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
