const Videos = () => {
  // Array containing the 6 YouTube video URLs
  const videoLinks = [
    "https://www.youtube.com/embed/hPQq2zZDGrM?si=rLBPStI9i_cN7k9I", // Video 1
    "https://www.youtube.com/embed/mPhbiSKcWMs?si=-DqR2Hz8S0FfngQ-", // Video 2
    "https://www.youtube.com/embed/aY3_JPEF6bs?si=dg3PNvLowZHANDXV", // Video 3
    "https://www.youtube.com/embed/zpDJdy-1pdI?si=W0QTBKQ9kqNi_mTZ", // Video 4
    "https://www.youtube.com/embed/SGVby8r57Xc?si=1P9AYwOUiJWzukR8", // Video 5
    "https://www.youtube.com/embed/1k-9-tPUVqY?si=g6SBmURbUJJaSTjI", // Video 6
  ];

  return (
    <section
      className="Vid-section py-16 bg-gray- text-center"
      style={{
        // backgroundImage: `url('/Images/section-vd.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment:'fixed',
      }}
    >
      <h2 className="text-white text-5xl font-bold mb-10">Videos</h2>

      {/* Grid for Video Thumbnails */}
      <div className="video grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {/* Embedding 6 YouTube videos */}
        {videoLinks.map((link, index) => (
          <div
            key={index}
            className="relative w-full max-w-[700px] mx-auto aspect-w-16 aspect-h-9 bg-gray-300 rounded-lg overflow-hidden"
          >
            <iframe
              className="w-full h-full sm:h-64 md:h-72 lg:h-96" // Adjust height for smaller screens
              src={link} // Use the video link from the array
              title={`YouTube video player ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>

      {/* View More Videos Button */}
      <button className="mt-8 px-6 py-3 bg-red-600 text-white rounded-lg shadow-lg hover:bg-indigo-700 hover:shadow-x3 transition-shadow duration-300">
        <a href="https://youtube.com/@the_epochouse?si=nU1XRQBoJPmngdqi">View More Videos</a>
      </button>
    </section>
  );
};

export default Videos;
