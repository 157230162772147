import { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import Navbar from '../Navbar'; 

const OurServices = () => {
  const services = [
    {
      id: 1,
      title: 'Music Production',
      content: 'We are ready to combine our creativity and technicality to shape your sound and create a suiting final product. The Epoch House can manage everything from songwriting to sound engineering, working with you to bring your musical ideas to life in various genres.',
      images: ['https://res.cloudinary.com/dts134dyc/image/upload/v1729171209/WhatsApp_Image_2024-10-17_at_14.13.00_1fb433af_ni39vj.jpg', '/images/ourservices/service1-2.jpg'],
      youtubeLink: 'https://www.youtube.com/embed/YOUR_VIDEO_ID_1' // Add YouTube link
    },
    {
      id: 2,
      title: 'Session Musicians',
      content: 'Epoch House offers Session Musician services, providing professional musicians to enhance your recordings or live performances. Whether you\'re a solo artist, band, or producer, our experienced musicians bring versatility across genres, ensuring high-quality contributions to your project. From drums and guitars to keyboards and vocals, Epoch House\'s session musicians seamlessly integrate with your creative vision, delivering the skilled, reliable support needed to elevate your music to the next level.',
      images: ['https://res.cloudinary.com/dts134dyc/image/upload/v1729171207/WhatsApp_Image_2024-10-17_at_14.12.59_a4bc8152_ez5kbb.jpg', '/images/ourservices/service2-2.jpg'],
      youtubeLink: 'https://www.youtube.com/embed/YOUR_VIDEO_ID_2' // Add YouTube link
    },
    {
      id: 3,
      title: 'Background Singers',
      content: 'At the Epoch House, our background singers are some of the world’s best. Carefully handpicked after years of training and dedication, the Epoch House’s background singers are a team of professional vocalists ready to enhance your music with rich, harmonious backing vocals. Whether you\'re recording in the studio or performing live, our talented singers add depth, texture, and style to your sound.',
      images: ['https://res.cloudinary.com/dts134dyc/image/upload/v1729168571/Photoroom-20241017_130149_2_fkdvnp.webp', 'https://res.cloudinary.com/dts134dyc/image/upload/v1729171209/WhatsApp_Image_2024-10-17_at_14.13.01_4014c2a2_ulk6g1.jpg'],
      youtubeLink: 'https://www.youtube.com/embed/YOUR_VIDEO_ID_3' // Add YouTube link
    },
    {
      id: 4,
      title: 'Mixing and Mastering',
      content: 'Epoch House’s Mixing and Mastering service ensures your music reaches its full potential. Mixing balances the individual elements like vocals, instruments, and effects, creating a cohesive sound. Mastering refines the final mix, enhancing clarity, loudness, and consistency across all playback systems. With professional attention to detail, Epoch House delivers polished tracks that sound incredible on any platform, making your music satisfying to the ears of your audience.',
      images: ['https://res.cloudinary.com/dts134dyc/image/upload/v1729171206/WhatsApp_Image_2024-10-17_at_14.12.55_a77a9964_ivq287.jpg', '/images/ourservices/service4-2.jpg'],
      youtubeLink: 'https://www.youtube.com/embed/YOUR_VIDEO_ID_4' // Add YouTube link
    },
    {
      id: 5,
      title: 'Show Production',
      content: 'Epoch House\'s Show Production service is designed to elevate live performances by providing comprehensive technical and creative support. From stage design and lighting to sound engineering and audiovisuals, Epoch House ensures every element of your show is perfectly synchronized. Whether it\'s a concert, festival, or corporate event, our team of experts will craft an unforgettable experience that captivates your audience.',
      images: ['https://res.cloudinary.com/dts134dyc/image/upload/v1729168570/Photoroom-20241017_130149_1_vlxq8a.webp', 'https://res.cloudinary.com/dts134dyc/video/upload/v1729174478/gg_rhakyj.mp4'],
      youtubeLink: 'https://www.youtube.com/embed/YOUR_VIDEO_ID_5' // Add YouTube link
    },
    {
      id: 6,
      title: 'Live Recording',
      content: 'At the Epoch House, our Live Recording service is premium. We capture the energy and authenticity of every artist\'s live performance in high-quality audio. Whether for bands, solo artists, or ensembles, the Epoch House provides a professional setup with state-of-the-art equipment and experienced sound engineers. If you are looking for an ideal way to capture raw and real-time music moments in a way that refreshes your audiences for a long time, our live recording is tailor-made for you.',
      images: ['https://res.cloudinary.com/dts134dyc/image/upload/v1729168571/Photoroom-20241017_130149_4_xoz0y5.webp', 'https://res.cloudinary.com/dts134dyc/video/upload/v1729174479/g_w7x3zs.mp4'],
      youtubeLink: 'https://www.youtube.com/embed/YOUR_VIDEO_ID_6' // Add YouTube link
    }
  ];
  const [activeService, setActiveService] = useState(null);

  return (
    <>
     <Helmet>
        <title>Our Services | Epoch House</title>
        <meta name="description" content="Epoch House provides services like Music Production, Session Musicians, Live Recording, and Show Production." />
        <meta name="keywords" content="Music Production, Session Musicians, Live Recording, Show Production, Epoch House" />
        <meta name="author" content="Epoch House" />
        <meta property="og:title" content="Our Services | Epoch House" />
        <meta property="og:description" content="Premium music production and live recording services at Epoch House." />
        <meta property="og:image" content="/images/ourservices/service1.jpg" />
        <meta property="og:url" content="https://epochousepro.com/services" />
      </Helmet>


      <Navbar />

      <section
        className="reuse-box relative bg-black bg-cover bg-center h-96 flex flex-col justify-center items-center text-white"
    
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Our Services</h1>
        <div className="flex items-center space-x-4 text-sm md:text-lg">
          <a href="/" className="text-white hover:text-red-500">Home</a>
          <span>|</span>
          <span className="text-red-600">Our Services</span>
        </div>
      </section>
      <div className='p-8' ><h3 className="text-5xl text-white head">Epoch House Services</h3></div>


      <section className="flex flex-col lg:flex-row w-full min-h-screen">
        {/* Sidebar for Larger Screens */}
        <aside className="hidden lg:block lg:w-1/4 bg-gray-100 p-6 space-y-6">
          {services.map(service => (
            <div
              key={service.id}
              className={`p-4 rounded-lg cursor-pointer transition-all duration-300 ease-in-out ${
                activeService?.id === service.id
                  ? 'bg-gray-800 text-white'
                  : 'bg-gray-100 hover:bg-gray-600 hover:text-white'
              }`}
              onClick={() => setActiveService(service)}
            >
              <div className="flex justify-between items-center">
                <span>{service.title}</span>
                <FaChevronRight />
              </div>
            </div>
          ))}
        </aside>

        {/* Content Area for Larger Screens */}
        <main className="hidden lg:block lg:w-3/4 p-6 bg-white shadow-lg">
          {activeService && (
            <>
              <h2 className="text-3xl font-bold mb-4">{activeService.title}</h2>
              <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} className="mb-4 lg:mb-6">
                {activeService.images.map((media, index) => (
                  <div key={index}>
                    {index === 1 ? (
                      <iframe
                        
                        width="100%"
                        height="650"
                        src={activeService.youtubeLink}
                        title={`YouTube video for ${activeService.title}`}
                        frameBorder="0"
                        allowFullScreen
                      />
                    ) : (
                      <img src={media} alt={activeService.title} className="big" />
                    )}
                  </div>
                ))}
              </Carousel>
              <p className="text-gray-700 text-lg leading-relaxed mb-4">
                {activeService.content}
              </p>
              <a href="https://wa.me/message/L4UXNZTPWMLSE1">
                <button className="py-2 px-4 bg-gray-700 text-yellow-500 font-semibold rounded-lg hover:bg-gray-900 transition duration-300">
                  I'm Interested
                </button>
              </a>
            </>
          )}
        </main>

        {/* Mobile & Tablet View */}
        <aside className="lg:hidden w-full bg-white shadow-lg p-6 space-y-6">
          {services.map(service => (
            <div key={service.id}>
              <div
                className={`p-4 rounded-lg cursor-pointer transition-all duration-300 ease-in-out ${
                  activeService?.id === service.id
                    ? 'bg-gray-800 text-white'
                    : 'bg-gray-100 hover:bg-gray-600 hover:text-white'
                }`}
                onClick={() => setActiveService(activeService?.id === service.id ? null : service)}
              >
                <div className="flex justify-between items-center">
                  <span>{service.title}</span>
                  <FaChevronRight />
                </div>
              </div>

              {/* Show content underneath the clicked tab */}
              {activeService?.id === service.id && (
                <div className="p-4 bg-white shadow-lg rounded-lg mt-4">
                  <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} className="mb-4 lg:mb-6">
                    {service.images.map((media, index) => (
                      <div key={index}>
                        {index === 1 ? (
                          <iframe
                          
                            width="100%"
                            height="250"
                            src={service.youtubeLink}
                            title={`YouTube video for ${service.title}`}
                            frameBorder="0"
                            allowFullScreen
                          />
                        ) : (
                          <img src={media} alt={service.title} className="s-carousel-img" />
                        )}
                      </div>
                    ))}
                  </Carousel>

                  <p className="text-gray-700 text-base leading-relaxed mb-4">
                    {service.content}
                  </p>
                  <a href="https://wa.me/message/L4UXNZTPWMLSE1">
                    <button className="py-2 px-4 bg-gray-700 text-yellow-500 font-semibold rounded-lg hover:bg-gray-900 transition duration-300">
                      I'm Interested
                    </button>
                  </a>
                </div>
              )}
            </div>
          ))}
        </aside>
      </section>

      <Footer />
    </>
  );
};

export default OurServices;
