import React, { useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import Modal from './Modal';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });


  const [isSubmitting, setIsSubmitting] = useState(false); // Add isSubmitting state
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      const response = await fetch('https://epoc-backend.vercel.app/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();

  
      if (response.ok) {
        setSuccessMessage(data.message || 'Message submitted successfully.');
        setIsSuccessModalOpen(true);
        setFormData({
          name: '',
    email: '',
    message: '',
        });
      } else {
        setErrorMessage(data.message || 'Error submitting form. Please try again.');
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      setErrorMessage('Network error. Please try again later.');
      setIsErrorModalOpen(true);
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false); // Reset loading state once submission completes or fails
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
     <Helmet>
        <title>Contact Us | The Epoch House</title>
        <meta name="description" content="Get in touch with The Epoch House to learn more about our music production services or book your session today." />
        <meta name="keywords" content="Contact Epoch House, Music Studio Contact, Book Studio Session" />
        <meta property="og:title" content="Contact Us | The Epoch House" />
        <meta property="og:url" content="https://epochousepro.com/contact-us" />
        <meta property="og:image" content="/images/section-contactus.jpg" />
        <meta name="author" content="The Epoch House" />
      </Helmet>
      <Navbar />
      
      <section className="reuse-box relative bg-black bg-cover bg-center h-96 flex flex-col justify-center items-center text-white" style={{ backgroundImage: `url(/images/section-contact.jpg)` }}>
        <h1 className="text-5xl font-bold mb-4">Contact Us</h1>
        <div className="flex items-center space-x-4 text-lg">
          <a href="/" className="text-white hover:text-red-500">Home</a>
          <span>|</span>
          <span className="text-red-600">Contact Us</span>
        </div>
      </section>

      <section className="py-16 px-4 md:px-16 lg:px-32 bg-gray-100">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          <div className="space-y-6">
            <h2 className="text-4xl font-bold text-gray-800">EPOCHOUSE</h2>
            <p className="text-gray-600">We are here to help. Reach out to us via any of the methods below or send us a direct message using the form.</p>
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <FaPhone className="text-black-600" />
                <span>+123 456 7890</span>
              </div>
              <div className="flex items-center space-x-2">
                <FaEnvelope className="text-black-600" />
                <span>info@epochouse.com</span>
              </div>
              <div className="flex items-center space-x-2">
                <FaMapMarkerAlt className="text-black-600" />
                <span>123 Main St, Hometown, Country</span>
              </div>
            </div>
            <div className="flex space-x-4 mt-6 text-4xl">
              <a href="https://facebook.com" className="text-indigo-600 hover:text-indigo-800"><FaFacebook /></a>
              <a href="https://twitter.com" className="text-blue-600 hover:text-indigo-600"><FaTwitter /></a>
              <a href="https://youtube.com" className="text-red-500 hover:text-red-600"><FaYoutube /></a>
              <a href="https://instagram.com" className="text-gray-500 hover:text-gray-700"><FaInstagram /></a>
            </div>
          </div>

          <div className="bg-white p-8 shadow-lg rounded-lg">
            <h2 className="text-3xl font-bold mb-6">Send Message</h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input type="text" name="name" placeholder="Name" required value={formData.name} onChange={handleChange} className="p-4 bg-gray-100 rounded-lg" />
                <input type="email" name="email" required placeholder="Email" value={formData.email} onChange={handleChange} className="p-4 bg-gray-100 rounded-lg" />
              </div>
              <textarea name="message" placeholder="Your message" required rows="6" value={formData.message} onChange={handleChange} className="w-full p-4 bg-gray-100 rounded-lg resize-none"></textarea>
              <button
                type="submit"
                className={`w-full py-3 text-white font-semibold rounded-lg transition duration-300 ${
                  isSubmitting ? 'bg-indigo-500 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />

{/* Success Modal */}
<Modal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        message={successMessage}
        type="success"
      />

      {/* Error Modal */}
      <Modal
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        message={errorMessage}
        type="error"
      />

    </>
  );
};

export default ContactUs;
