import React from 'react';
import { motion } from 'framer-motion'; // Import framer motion

const Modal = ({ isOpen, onClose, message, type }) => {
  if (!isOpen) return null;

  const modalStyles = {
    backgroundColor: type === 'success' ? '#d1e7dd' : '#f8d7da',
    color: type === 'success' ? '#0f5132' : '#842029',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '400px',
    width: '100%',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
  };

  const overlayStyles = {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 50,
  };

  const buttonStyles = {
    backgroundColor: '#6366f1',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const buttonHoverStyles = {
    backgroundColor: '#4f46e5',
  };

  return (
    <div style={overlayStyles}>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 0.3 }}
        style={modalStyles}
      >
        <h2 style={{ fontSize: '1.5rem', marginBottom: '16px', textAlign: 'center' }}>
          {type === 'success' ? 'Success!' : 'Error!'}
        </h2>
        <p style={{ textAlign: 'center', marginBottom: '16px' }}>{message}</p>
        <div style={{ textAlign: 'center' }}>
          <button
            style={buttonStyles}
            onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyles.backgroundColor)}
            onMouseOut={(e) => (e.target.style.backgroundColor = buttonStyles.backgroundColor)}
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default Modal;
