import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const CreativeDirector = () => {
  return (
    <section
      className="py-16 text-center mcd-section"
      style={{
        // backgroundImage: `url(${process.env.PUBLIC_URL}/Images/section-cd.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        backgroundAttachment:'fixed',
        padding:"30px 0px 30px 0px"
      }}
    >
      <div className='mcd-container'>
        {/* Image Container */}
      <div className="flex justify-center items-center ">
        <div
          className="rounded-full shadow-lg w-48 h-48 sm:w-64 sm:h-64 md:w-80 md:h-80 lg:w-96 lg:h-96 mb-8 bg-cover bg-center"
          style={{
            backgroundImage: `url(https://res.cloudinary.com/dts134dyc/image/upload/v1729171203/WhatsApp_Image_2024-10-17_at_14.12.25_697a1014_emq6lg.jpg)`,
 
          }}
        >
          {/* Circular div with background image, no need for <img> */}
        </div>
      </div>

    <div className='mcd-text'>  {/* Text Content */}
      <h1 className="text-2xl sm:text-3xl lg:text-4xl text-white font-bold mb-4">
        Meet our Creative Director
      </h1>
      <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl mx-6 ">
        Itunu Joe is the creative force behind The Epoch House. He worked his way up the musical industry’s ecosystem, having started as a studio assistant after college. He’s a skilled drummer, composer, music producer, and audio engineer with a deep understanding of various musical styles. While owning a musical band that travels around the world for performances, Itunu also directs a choir of 50 singers in his local church.
        
        His experience in audio engineering adds to his expertise, making him a versatile talent in the music industry. Itunu’s background in engineering also plays a key role in his work, bringing a unique blend of technical precision and artistic creativity to every project he takes on. With his diverse skill set, Itunu can craft music and sound with both passion and excellent attention to detail, making his outcomes spectacular.
      </p>

      {/* Link to About Us page */}
      <Link to="/about" className="inline-block mt-6">
        <button className=" text-white rounded-full hover:bg-gray-900 transition-transform transform hover:scale-105">
          Learn More
        </button>
      </Link>
      </div>
      </div>
      
    
    </section>
  );
};

export default CreativeDirector;
