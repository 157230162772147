// src/pages/Blog.js
import React, { useEffect, useState } from 'react';
import BlogPost from '../components/BlogPost';
import { db } from '../services/firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import styled from 'styled-components';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import  Spinner  from '../components/Spinner'


const BlogContainer = styled.div`
  display:flex;
  flex-wrap:wrap;
  gap:60px;
  margin: auto;
  padding: 70px 20px 70px 20px;
 font-family: Arial, Helvetica, sans-serif;

`;


const PostDiv = styled.div`
flex: 1 1 45%;
min-width:300px;
`;


// Format the timestamp for display
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

function Blog() {
  const [posts, setPosts] = useState([]);
  const [isLoading,setIsLoading]=useState(false)

  useEffect(() => {
    // Fetch blog posts from Firestore
    const fetchPosts = async () => {
      try {
        setIsLoading(true)
        const q = query(collection(db, 'posts'), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const postsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }finally{
        setIsLoading(false)
      }
    };

    fetchPosts();
  }, []);

  return (  
    <>
     <Helmet>
        <title>Blog | The Epoch House</title>
        <meta name="description" content="Discover the latest updates, industry insights, and creative inspiration from The Epoch House. Stay updated on our services, client stories, and more!" />
        <meta name="keywords" content="Epoch House Blog, Music Production Blog, Studio Updates, Creative Insights" />
        <meta property="og:title" content="Blog | The Epoch House" />
        <meta property="og:description" content="Stay updated with The Epoch House's latest blog posts on music production, studio insights, and more." />
        <meta property="og:url" content="https://epochousepro.com/blog" />
        <meta property="og:image" content="/images/section-blog.jpg" />
        <meta name="author" content="The Epoch House" />
      </Helmet>
      <Navbar />

{/* Background section */}
<section
        className="reuse-box relative bg-black bg-cover bg-center h-96 flex flex-col justify-center items-center text-white"
        style={{ backgroundImage: `url('/images/section-about.jpg')` }} // Use the correct background image here
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Our Blog</h1>
        <div className="flex items-center space-x-4 text-sm md:text-lg">
          <a href="/" className="text-white hover:text-red-500">Home</a>
          <span>|</span>
          <span className="text-red-600">Blog</span> {/* Highlight the current page */}
        </div>
      </section>

      {isLoading?<Spinner/>: <div> <BlogContainer>


        
        
        {posts.length > 0 ? (
          posts.map((post) => (
            <PostDiv className='Post-div' key={post.id} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #dddddd', borderRadius: '8px' }}>
              <BlogPost post={post} />
              <p style={{ fontSize: '0.875rem', color: '#666666', marginTop: '3px' }}>
                Posted on: {post.createdAt ? formatTimestamp(post.createdAt.toDate()) : 'N/A'}
              </p>
            </PostDiv>
          ))
        ) : (
          <p>No posts available.</p>
        )}
        
      </BlogContainer>
      <Footer />
      </div>
      }
    </>
  );
}

export default Blog;
